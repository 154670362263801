import React from 'react'
import { chosen } from '../../../config/constants'
import styles from './Chosen.module.scss'

const Chosen = (props) => (
	<section className={`${styles['chosen']} ${styles[props.formType]} ${styles[props.version]}`} id='chosen'>
		<div className={`${styles['chosen__caption']} caption-title`}>
			Нас выбирают
		</div>
		<div className={styles['chosen__wrapper']}>
			<div className={`${styles['chosen__block']} ${styles['red']}`}>
				{chosen.red.map((item, idx) => (
					<div className={styles['chosen__block-inner']} key={idx}>
						<img src={item} alt="" />
					</div>
				))}
			</div>
			<div className={`${styles['chosen__block']} ${styles['blue']}`}>
				{chosen.blue.map((item, idx) => (
					<div className={styles['chosen__block-inner']} key={idx}>
						<img src={item} alt="" />
					</div>
				))}
			</div>
		</div>
	</section>
)

export { Chosen }
