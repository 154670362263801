import React from 'react'

const Logo = () => (
  <svg width="198" height="50" viewBox="0 0 198 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22.5286 0.349367L41.3011 11.3592C42.0988 11.8331 42.5855 12.6936 42.5803 13.6206L42.636 35.7415C42.6427 36.6648 42.1614 37.5231 41.3698 37.9997L22.6562 49.1108C21.8718 49.5768 20.8952 49.5768 20.1108 49.1108L1.33825 38.101C0.543634 37.6288 0.057369 36.7729 0.059049 35.8494L0.000159739 13.7285C-0.0100697 12.8017 0.471634 11.9389 1.26628 11.4605L19.98 0.349367C20.7655 -0.116456 21.743 -0.116456 22.5286 0.349367ZM34.3229 35.149C34.513 35.0328 34.6913 34.9238 34.856 34.8231C36.4329 33.8591 36.6423 32.5257 36.6423 31.8395C36.6423 29.6793 36.6423 29.0878 33.8745 27.6466C33.8745 27.6466 25.7249 23.1891 24.8808 22.7153C24.0367 22.2414 23.0094 22.5715 23.0094 24.3493V26.8002C22.9807 27.422 23.3131 28.0047 23.8633 28.297C24.8546 28.8395 27.1218 30.0682 29.6999 31.3754L22.6266 35.0748C21.8411 35.5406 20.8636 35.5406 20.078 35.0748L13.2076 31.0486C12.412 30.5758 11.9256 29.7184 11.9284 28.7937L11.9088 20.7087C11.902 19.7855 12.3833 18.9272 13.1749 18.4506L20.029 14.3819C20.8133 13.916 21.79 13.916 22.5743 14.3819L29.0652 18.1924L34.7153 21.2806C35.1258 21.5006 35.6224 21.4861 36.0192 21.2424C36.4161 20.9988 36.6534 20.5628 36.6423 20.0976V17.1401C36.6398 16.2126 36.1456 15.3557 35.3434 14.8885L22.535 7.37214C21.7507 6.90619 20.774 6.90619 19.9897 7.37214L7.23037 14.9538C6.43977 15.4298 5.95867 16.2867 5.96425 17.2087L6.00351 32.3035C6.00183 33.2271 6.4881 34.083 7.28272 34.5551L20.0911 42.0715C20.8754 42.5374 21.8521 42.5374 22.6364 42.0715C22.6893 42.2609 30.6797 37.3762 34.3229 35.149Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M52.5508 24.6163C52.5508 23.0225 52.8523 21.5188 53.456 20.105C54.0597 18.6906 54.8873 17.4719 55.9396 16.4494C56.9918 15.4256 58.258 14.6163 59.7381 14.02C61.2176 13.4238 62.8041 13.125 64.4963 13.125C66.3681 13.125 68.0234 13.4019 69.4635 13.9538C70.9036 14.5063 72.2404 15.3194 73.4741 16.3925L69.8726 20.715C69.0487 20.025 68.2142 19.5094 67.3696 19.1694C66.5251 18.8294 65.5773 18.6594 64.5276 18.6594C62.928 18.6594 61.5824 19.2431 60.4882 20.4113C59.3947 21.5794 58.8479 23.0113 58.8479 24.7075C58.8479 26.5163 59.4134 28.0087 60.5426 29.185C61.6724 30.3612 63.1138 30.9488 64.8673 30.9488C66.3005 30.9488 67.4872 30.6294 68.4263 29.99V27.2075H64.0165V22.7712H74.2286V32.7819C71.4522 35.0438 68.2492 36.1738 64.6202 36.1738C62.3399 36.1738 60.2818 35.6863 58.4463 34.71C56.6096 33.7338 55.1682 32.3625 54.1216 30.5963C53.0744 28.8306 52.5508 26.8369 52.5508 24.6163Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M80.0781 35.9175V13.75H86.2207V30.5225H96.9745V35.9175H80.0781Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M105.141 24.6495C105.141 26.2901 105.677 27.6989 106.75 28.8751C107.823 30.0514 109.18 30.6395 110.82 30.6395C112.48 30.6395 113.837 30.0595 114.889 28.8982C115.941 27.7376 116.467 26.3214 116.467 24.6495C116.467 23.0089 115.931 21.6001 114.858 20.4239C113.785 19.2476 112.419 18.6595 110.758 18.6595C109.118 18.6595 107.771 19.2395 106.719 20.4007C105.667 21.562 105.141 22.9782 105.141 24.6495ZM98.8438 24.6494C98.8438 23.0819 99.1503 21.5894 99.764 20.1706C100.378 18.7531 101.211 17.5287 102.263 16.4975C103.315 15.4663 104.584 14.6463 106.069 14.0375C107.555 13.4294 109.138 13.125 110.82 13.125C112.501 13.125 114.08 13.4269 115.555 14.03C117.03 14.6338 118.29 15.4481 119.334 16.4738C120.377 17.5006 121.2 18.7225 121.801 20.14C122.403 21.5581 122.703 23.0613 122.703 24.6494C122.703 26.215 122.397 27.7081 121.785 29.1294C121.173 30.5506 120.342 31.7763 119.292 32.8063C118.243 33.8363 116.978 34.6544 115.496 35.2625C114.015 35.87 112.435 36.1738 110.758 36.1738C108.519 36.1738 106.487 35.6613 104.661 34.6369C102.836 33.6125 101.409 32.2219 100.383 30.4656C99.3567 28.71 98.8438 26.7713 98.8438 24.6494Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M133.016 31.0638H137.756C138.693 31.0638 139.419 30.88 139.934 30.5125C140.448 30.1444 140.705 29.615 140.705 28.9219C140.705 28.2919 140.453 27.7956 139.949 27.4331C139.444 27.0713 138.693 26.89 137.695 26.89H133.016V31.0638ZM133.016 22.5464H136.875C138.81 22.5464 139.778 21.8732 139.778 20.5282C139.778 19.9132 139.541 19.4382 139.067 19.1051C138.594 18.7714 137.904 18.6039 136.998 18.6039H133.016V22.5464ZM126.996 35.9175V13.75H138.369C141.071 13.75 143.089 14.405 144.419 15.7137C145.378 16.6587 145.857 17.8562 145.857 19.3075C145.857 21.6325 144.716 23.295 142.434 24.2956C143.869 24.7769 144.964 25.4544 145.717 26.3281C146.471 27.2019 146.848 28.3813 146.848 29.8662C146.848 31.7556 146.108 33.2369 144.626 34.3094C143.144 35.3819 141.083 35.9175 138.44 35.9175H126.996Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M151.395 26.1944V13.75H157.63V26.0819C157.63 27.6394 157.991 28.8156 158.713 29.61C159.435 30.4037 160.435 30.8013 161.715 30.8013C162.994 30.8013 163.995 30.4194 164.717 29.6562C165.438 28.8925 165.8 27.7531 165.8 26.2362V13.75H172.036V26.04C172.036 27.8019 171.793 29.3431 171.309 30.6619C170.825 31.9813 170.125 33.0531 169.208 33.8775C168.292 34.7019 167.202 35.3175 165.941 35.725C164.678 36.1313 163.249 36.3356 161.653 36.3356C158.404 36.3356 155.882 35.4925 154.087 33.8081C152.292 32.1225 151.395 29.585 151.395 26.1944Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M177.043 32.7463L180.316 28.5351C182.605 30.2201 185.039 31.0119 187.62 30.9113C188.432 30.8801 189.048 30.7194 189.47 30.4294C189.891 30.1407 190.091 29.7382 190.071 29.2226C190.053 28.7494 189.783 28.3732 189.263 28.0944C188.742 27.8151 187.745 27.5344 186.273 27.2513C185.515 27.1057 184.857 26.9688 184.297 26.8407C183.738 26.7132 183.143 26.5482 182.514 26.3457C181.884 26.1432 181.347 25.9269 180.903 25.6963C180.458 25.4663 180.023 25.1844 179.595 24.8501C179.167 24.5163 178.822 24.1507 178.558 23.7532C178.293 23.3563 178.075 22.8882 177.904 22.3476C177.733 21.8082 177.634 21.2132 177.609 20.5644C177.528 18.4744 178.239 16.7401 179.743 15.3613C181.248 13.9826 183.313 13.2419 185.939 13.1401C189.579 12.9982 192.659 13.8582 195.18 15.7194L192.265 20.1807C190.138 18.8088 188.043 18.1632 185.983 18.2432C185.264 18.2713 184.719 18.4294 184.349 18.7169C183.979 19.0044 183.803 19.3701 183.821 19.8126C183.841 20.3276 184.11 20.7169 184.63 20.9801C185.15 21.2438 186.183 21.5207 187.729 21.8107C189.097 22.0569 190.263 22.3388 191.228 22.6569C192.193 22.9757 193.065 23.3876 193.845 23.8932C194.626 24.4001 195.217 25.0288 195.618 25.7813C196.021 26.5344 196.242 27.4257 196.282 28.4551C196.369 30.6901 195.622 32.4826 194.043 33.8332C192.462 35.1838 190.311 35.9119 187.587 36.0182C185.584 36.0957 183.674 35.8582 181.853 35.3051C180.033 34.7519 178.43 33.8994 177.043 32.7463Z" fill="white"/>
  </svg>
)

export {Logo}