import React from 'react'
import {Repeat} from "../../icons/Repeat/Repeat";
import styles from './SuccessText.module.scss'

const SuccessText = (props) => (
  <div className={styles['success-text']}>
    Заявка отправлена
    <button type='button' onClick={props.successText}>Отправить еще раз <Repeat/></button>
  </div>
)

export {SuccessText}