import React from 'react'
import styles from './Expert.module.scss'
import { expertList } from '../../../config/constants'

const Expert = (props) => (
	<div className={`${styles['expert__external-wrapper']} ${styles[props.version]}`}>
		<div className="container">
			<section className={`${styles.expert}`} id="expert">
				<div className={`${styles['expert__caption']} caption-title`}>
					экспертизы полного цикла
				</div>
				<div className={styles['expert__wrapper']}>
					{expertList.map((item, idx) => (
						<div className={styles['expert__block']} key={idx}>
							<img src={item.src} alt="" />
							<span>{item.title}</span>
							{item.description}
						</div>
					))}
				</div>
			</section>
		</div>
	</div>
)

export { Expert }
