import React from 'react'

const LogoBlack = () => (
  <svg width="140" height="22" viewBox="0 0 140 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.00122137 10.9636C-0.00681163 9.48516 0.290855 8.02064 0.875979 6.65982C1.42993 5.35157 2.24403 4.16646 3.27005 3.17469C4.32471 2.16957 5.57074 1.38114 6.93598 0.855067C8.39101 0.281641 9.94469 -0.00795936 11.5112 0.00226275C13.1448 -0.0277037 14.7699 0.240198 16.3051 0.792528C17.722 1.33821 19.0309 2.12525 20.1725 3.11784L16.7195 7.23973C15.9974 6.62887 15.184 6.13215 14.3081 5.76722C13.4333 5.43165 12.5013 5.26565 11.563 5.27828C10.0809 5.25361 8.66039 5.86304 7.66686 6.94977C6.6147 8.0577 6.04619 9.5302 6.08424 11.0489C6.02985 12.6307 6.6184 14.1682 7.71866 15.3186C8.80132 16.4465 10.3182 17.0666 11.891 17.0242C13.1095 17.0738 14.3143 16.7544 15.344 16.1089V13.4311H11.0623V9.20118H20.9091V18.764C18.3066 20.8936 15.0227 22.0401 11.6436 21.9989C9.57032 22.0254 7.5231 21.5408 5.68715 20.589C3.95331 19.6912 2.50811 18.3324 1.51478 16.6661C0.494528 14.9367 -0.0287826 12.9651 0.00122137 10.9636Z" fill="#151F31"/>
    <path d="M26.3633 21.0833V0H32.319V15.9514H42.7269V21.055L26.3633 21.0833Z" fill="#151F31"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M45.4637 6.71903C44.855 8.06643 44.5431 9.52057 44.5476 10.9897C44.5246 12.949 45.0558 14.8773 46.0843 16.5684C47.0958 18.2403 48.5606 19.6162 50.322 20.5491C53.6352 22.2601 57.5667 22.4684 61.0552 21.1177C62.453 20.5745 63.7277 19.7749 64.8083 18.7635C65.8573 17.7643 66.6944 16.5784 67.2729 15.2718C67.879 13.9219 68.1889 12.466 68.1831 10.9954C68.1916 9.51899 67.8879 8.0563 67.2906 6.69628C66.7239 5.38602 65.8922 4.19721 64.8437 3.19897C63.7684 2.19228 62.497 1.39992 61.1025 0.867422C58.0853 -0.289141 54.7222 -0.289141 51.705 0.867422C50.3032 1.40712 49.0243 2.2049 47.9401 3.21603C46.8838 4.21607 46.0423 5.40636 45.4637 6.71903ZM52.3847 15.0216C51.3306 13.927 50.7586 12.4817 50.7889 10.9897C50.7369 9.4818 51.2965 8.01352 52.3492 6.89532C53.3616 5.79838 54.8264 5.18841 56.3506 5.22912C57.8995 5.19251 59.386 5.81706 60.411 6.93513C61.4635 8.03073 62.0352 9.4753 62.0068 10.967C62.0461 12.461 61.4875 13.9125 60.4464 15.0216C59.3991 16.0888 57.941 16.6936 56.4156 16.6936C54.8901 16.6936 53.432 16.0888 52.3847 15.0216Z" fill="#151F31"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M71.8164 21.0833V0H82.7363C85.3336 0 87.2701 0.621877 88.5458 1.86563C89.4633 2.76088 89.9634 3.99334 89.9265 5.268C89.9265 7.47954 88.8303 9.06164 86.6379 10.0143C87.8479 10.3636 88.9391 11.032 89.7948 11.948C90.5712 12.9014 90.9649 14.1048 90.9005 15.3277C90.9622 17.0045 90.1595 18.5977 88.7692 19.5579C87.3484 20.5749 85.368 21.0833 82.828 21.0833H71.8164ZM77.5972 8.36415H81.304C83.1603 8.36415 84.0884 7.72526 84.0884 6.44748C84.1128 5.90952 83.8551 5.39715 83.4067 5.09221C82.8082 4.73745 82.1148 4.57131 81.4186 4.61588H77.5972V8.36415ZM82.1462 16.4675H77.5972V12.498H82.0889C82.8472 12.4462 83.6032 12.6264 84.2546 13.0141C84.7247 13.3336 84.9987 13.8675 84.9822 14.4317C85.0124 15.0294 84.7313 15.6007 84.2374 15.9458C83.6111 16.3282 82.8809 16.5104 82.1462 16.4675Z" fill="#151F31"/>
    <path d="M96.3633 12.1262V0H102.396V12.0158C102.396 13.5381 102.742 14.6828 103.447 15.4555C104.194 16.2486 105.248 16.6732 106.333 16.6176C107.409 16.6738 108.458 16.2684 109.22 15.502C109.916 14.7583 110.264 13.6466 110.264 12.1669V0H116.361V11.9751C116.391 13.5076 116.155 15.0336 115.662 16.484C115.249 17.6806 114.549 18.7562 113.624 19.6158C112.711 20.4355 111.634 21.0496 110.467 21.417C109.121 21.8261 107.721 22.0222 106.316 21.998C103.175 21.998 100.735 21.1768 98.9957 19.5344C97.2562 17.892 96.3787 15.4226 96.3633 12.1262Z" fill="#151F31"/>
    <path d="M120.91 18.8475L124.129 14.8381C126.194 16.3945 128.756 17.2048 131.372 17.1292C132.016 17.143 132.65 16.9844 133.207 16.671C133.615 16.4138 133.844 15.9597 133.802 15.4899C133.76 15.0201 133.453 14.6109 133.005 14.4257C132.059 14.0341 131.065 13.7608 130.047 13.6123C129.301 13.4749 128.644 13.3431 128.088 13.2229C127.532 13.1026 126.946 12.9365 126.313 12.7417C125.764 12.578 125.229 12.3711 124.715 12.1231C124.259 11.8895 123.826 11.6173 123.419 11.3098C123.023 11.0113 122.675 10.6559 122.39 10.2559C122.113 9.83533 121.896 9.38067 121.745 8.90414C121.568 8.34651 121.469 7.76872 121.449 7.18582C121.321 5.29212 122.103 3.44723 123.567 2.18551C125.054 0.833765 127.1 0.110162 129.703 0.0146993C133.309 -0.122766 136.365 0.703937 138.87 2.49481L135.982 6.77915C134.156 5.54614 131.977 4.8954 129.751 4.91764C129.174 4.90284 128.607 5.06307 128.129 5.37586C127.781 5.61807 127.582 6.01477 127.603 6.42976C127.625 6.92144 127.939 7.35649 128.408 7.54667C129.394 7.93264 130.425 8.20188 131.479 8.34855C132.655 8.53916 133.816 8.81096 134.952 9.16189C135.868 9.44613 136.741 9.84471 137.55 10.3475C138.291 10.7973 138.902 11.4221 139.325 12.1632C139.741 12.9598 139.965 13.837 139.982 14.7292C140.139 16.6926 139.314 18.6097 137.763 19.8842C136.185 21.1787 134.051 21.8775 131.36 21.9806C129.43 22.0708 127.498 21.8462 125.644 21.3161C123.911 20.8096 122.301 19.97 120.91 18.8475Z" fill="#151F31"/>
  </svg>
)

export {LogoBlack}