import React from 'react'
import { Link } from 'react-scroll'
import styles from './DashboardLink.module.scss'

const DashboardLink = (props) => {
	return (
		<div className={styles['dashboard-link']}>
			<div className={styles['dashboard-link__wrapper']}>
				{props.dashboardLink.map((item, idx) => (
					<Link
						activeClass={styles.active}
						to={item.link}
						spy={true}
						smooth={true}
						offset={50}
						duration={500}
						key={idx}
					>
						<div className={`${styles['dashboard-link__block']} link-section`}>
							<span>{item.title}</span>
							<div className={styles['dashboard-link__square']} />
						</div>
					</Link>
				))}
			</div>
		</div>
	)
}

export { DashboardLink }
