import React from 'react'
import styles from './Advantages.module.scss'
import {advantagesList} from "../../../config/constants";

const Advantages = () => (
  <section className={`${styles.advantages} content-wrapper`}
           id='advantages'
           data-name='Преимущества'>
    <div className={`${styles['advantages__caption']} caption-title`}>
      Преимущества
    </div>
    <div className={styles['advantages__wrapper']}>
      {advantagesList.map((item, idx) => (
        <div className={styles['advantages__block']} key={idx}>
          <div className={`${styles['advantages__block-inner']} ${styles['red']}`}>
            <p>{item.red.title}</p>
          </div>
          <span>{idx + 1}</span>
          <div className={`${styles['advantages__block-inner']} ${styles['blue']}`}>
            <p>{item.blue.title}</p>
          </div>
        </div>
      ))}
    </div>
  </section>
)

export {Advantages}