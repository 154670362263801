import React from 'react'
import { Arrow } from '../../icons/Arrow/Arrow'
import styles from './ChangeMobilePageBtn.module.scss'

const ChangeMobilePageBtn = (props) => (
	<div className={`${styles['change-page-btn']} ${styles[props.formType]}`} onClick={() => props.changePage(props.formType)}>
		<span>{props.title}</span>
		<Arrow />
	</div>
)

export { ChangeMobilePageBtn }
