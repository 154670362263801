import React, { Component } from 'react'
import { Stroke } from '../../icons/Stroke/Stroke'
import { Arrow } from '../../icons/Arrow/Arrow'
import emailjs from 'emailjs-com'
import styles from './Form.module.scss'

class Form extends Component {
	state = {
		agreement: true,
		name: '',
		other: '',
		validate: false
	}

	checkedAgreement = (e) => {
		const checked = e.target.checked
		this.setState((prevState) => ({
			agreement: checked,
			validate: !prevState.validate
		}), () => {
			this.validateForm()
		})
	}

	checkedAgreementText = () => {
		this.setState((prevState) => ({
			agreement: !prevState.agreement,
			validate: !prevState.validate
		}))
	}

	sendMail = (e) => {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_9b3b8md',
				'template_d907t0k',
				e.target,
				'user_iGuVnWLsEUWwTVgArIjEN'
			)
			.then(
				(result) => {
					console.log(result.text)
					this.setState({
						name: '',
						other: ''
					}, () => {
						this.props.successText()
					})
				},
				(error) => {
					console.log(error.text)
				}
			)
	}

	changeValue = (e) => {
		const name = e.target.name
		const val = e.target.value
		this.setState({
			[name]: val
		}, () => {
			this.validateForm()
		})
	}

	validateForm = () => {
		const {name, other, agreement} = this.state
		if (name !== '' && other !== '' && agreement) {
			this.setState({
				validate: true
			})
		} else {
			this.setState({
				validate: false
			})
		}
	}

	render() {
		const { type, currentTypeForm, main, formType } = this.props
		const { name, other, validate } = this.state
		console.log(other)
		return (
			<div className={styles.form}>
				<div className={styles['form__caption']}>{this.props.title}</div>
				<form onSubmit={this.sendMail}>
					<input
						type="text"
						name="name"
						value={name}
						onChange={(e) => this.changeValue(e)}
						placeholder="Ваше имя*"
						onClick={() => currentTypeForm(type, main)}
					/>
					<input
						type="text"
						name="other"
						value={other}
						onChange={(e) => this.changeValue(e)}
						placeholder="Почта или телефон*"
						onClick={() => currentTypeForm(type, main)}
					/>
					<input type="hidden" name="type" value={formType} />
					<div className={styles['form__checkbox-wrapper']}>
						<label htmlFor="form__checkbox">
							{this.state.agreement && <Stroke />}
							<input
								type="checkbox"
								className={styles['form__checkbox']}
								checked={this.state.agreement}
								onChange={this.checkedAgreement}
							/>
						</label>
						<div onClick={this.checkedAgreementText}>
							Я согласен на{' '}
							<a href="https://globus-ltd.ru/agreement">обработку</a> моих
							персональных данных*
						</div>
					</div>
					<button type="submit" disabled={!validate}>
						Отправить <Arrow />
					</button>
				</form>
			</div>
		)
	}
}

export { Form }
