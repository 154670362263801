import React from 'react'
import styles from './CardProject.module.scss'

const CardProject = ({info: {src, title, description}, type}) => {
	return (
		<div className={styles['card-project']} datatype={type}>
			<img src={src} alt="" />
			<div className={styles['card-project__block']}>
				<span>{title}</span>
				{description}
			</div>
		</div>
		)
}

export { CardProject }
