import scrollMonitor from 'scrollmonitor'
import styles from '../components/DashboardLink/DashboardLink.module.scss'

class linkSection {
	constructor() {
		this.elems = document.querySelectorAll('.content-wrapper')
		this.links = document.querySelectorAll('.link-section')
	}

	init() {
		this.elems.forEach((elem, idx) => {
			const elemName = elem.getAttribute('data-name')
			const watcher = scrollMonitor.create(
				elem,
				elemName !== 'Контакты'
					? { top: 0, bottom: -350 }
					: { top: 0, bottom: 0 }
			)

			watcher.enterViewport(() => {
				this.links[idx].classList.add(styles.active)
			})
			watcher.exitViewport(() => {
				this.links[idx].classList.remove(styles.active)
			})
		})
	}
}

export { linkSection }
