import React, { Component } from 'react'
import { Logo } from '../../../icons/Logo/Logo'
import { offerText } from '../../../config/constants'
import { Arrow } from '../../../icons/Arrow/Arrow'
import { gsap } from 'gsap'
import styles from './Offer.module.scss'

class Offer extends Component {
	componentDidMount() {
		const block = document.querySelectorAll(`.${styles['offer__block-inner']}`)
		const tl = gsap.timeline()
		tl.from(block[0], { opacity: 0, delay: 0 })
		tl.from(block[1], { opacity: 0, delay: 0 })
	}

	render() {
		const {
			version,
			formType,
			showMobileContent,
			changeMobileType
		} = this.props
		return (
			<section
				className={`${styles.offer} ${styles[formType]} ${version}`}
				id="offer"
			>
				<a href="https://globus-ltd.ru/">
					<Logo />
				</a>
				<div className={styles['offer__wrapper']}>
					{!showMobileContent && version === 'mobile-ver' && (
						<div className={styles['offer__block']}>
							<div className={styles['offer__caption']}>
								Наша программа по решению ваших ИТ-задач
							</div>
							<img src="/img/Bulletin.png" alt="" />
							<div className={styles['offer__block-nav']}>
								<div
									className={styles['offer__btn']}
									onClick={() => changeMobileType('red')}
								>
									<div
										className={`${styles['offer__arrow']} ${styles['offer__arrow_left']}`}
									>
										<Arrow />
									</div>
									<span>Проектные команды</span>
								</div>
								<div
									className={styles['offer__btn']}
									onClick={() => changeMobileType('blue')}
								>
									<div
										className={`${styles['offer__arrow']} ${styles['offer__arrow_right']}`}
									>
										<Arrow />
									</div>
									<span>Аутстаффинг разработчиков</span>
								</div>
							</div>
						</div>
					)}
					{version === 'desktop-ver' && (
						<div className={styles['offer__block']}>
							<div className={styles['offer__caption']}>
								Наша программа по решению ваших ИТ-задач
							</div>
							<img src="/img/Bulletin.png" alt="" />
						</div>
					)}
					{showMobileContent && version === 'mobile-ver' && (
						<div
							className={`${styles['offer__block']} ${styles['offer__block_description']}`}
						>
							{offerText.map((item, idx) => (
								<div
									className={`${styles['offer__block-inner']} ${
										styles[item.type]
									}`}
									key={idx}
								>
									<span>{item.title}</span>
									{item.description}
								</div>
							))}
						</div>
					)}
					{version === 'desktop-ver' && (
						<div
							className={`${styles['offer__block']} ${styles['offer__block_description']}`}
						>
							{offerText.map((item, idx) => (
								<div
									className={`${styles['offer__block-inner']} ${item.type}`}
									key={idx}
								>
									<span>{item.title}</span>
									{item.description}
								</div>
							))}
						</div>
					)}
				</div>
			</section>
		)
	}
}

export { Offer }
