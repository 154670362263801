import React from 'react'
import { LogoBlack } from '../../icons/LogoBlack/LogoBlack'
import styles from './Footer.module.scss'

const Footer = (props) => (
	<div className={`${styles['footer__external-wrapper']} ${styles[props.version]}`}>
		<div className="container">
			<section className={`${styles.footer} content-wrapper`}
							 id='footer'
							 data-name='Контакты'>
				<div className={styles['footer__wrapper']}>
					<div className={styles['footer__block']}>
						<div className={styles['footer__block-inner']}>
							<div className={styles['footer__logo']}>
								<a href="#">
									<LogoBlack />
								</a>
								© 2020
							</div>
						</div>
						<div className={styles['footer__contact']}>
							<span>contact@globus-ltd.com</span>
							<p>8 (800) 775 13 96</p>
							<p>+7 (495) 175-13-96</p>
						</div>
					</div>
					<div className={styles['footer__block']}>
						<div className={styles['footer__block-inner']}>
							<div className={styles['footer__contact']}>
								<span>Москва</span>
								<p>Земляной Вал, 50а стр.3</p>
							</div>
						</div>
						<div className={styles['footer__contact']}>
							<span>Нижний Новгород</span>
							<p>Нижневолжская наб., 16</p>
							<p>Казанская наб., 5</p>
						</div>
					</div>
					<div className={styles['footer__block']}>
						<div className={styles['footer__block-inner']}>
							<div className={styles['footer__contact']}>
								<p>Будем рады пообщаться или обсудить ваш проект</p>
							</div>
						</div>
					</div>
					<div className={styles['footer__block']}>
						<div className={styles['footer__social']}>
							<p>Делимся интересным</p>
							<div className={styles['footer__social-inner']}>
								<a href="#">
									<img src="/img/icons/insta_red.svg" alt="" />
								</a>
								<a href="#">
									<img src="/img/icons/vk_red.svg" alt="" />
								</a>
								<a href="#">
									<img src="/img/icons/fb_red.svg" alt="" />
								</a>
								<a href="#">
									<img src="/img/icons/Youtube_red.svg" alt="" />
								</a>
								<a href="#">
									<img src="/img/icons/Shape.svg" alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
)

export { Footer }
