import React, { Component } from 'react'
import { MatchMediaBreakpoint } from 'match-media-breakpoint'
import { BREAKPOINTS } from './config/constants'
import { Offer } from './components/section/Offer/Offer'
import { CurrentProject } from './components/section/CurrentProject/CurrentProject'
import { RunningString } from './components/RunningString/RunningString'
import { Advantages } from './components/section/Advantages/Advantages'
import { Chosen } from './components/section/Chosen/Chosen'
import { FormSection } from './components/section/FormSection/FormSection'
import { Expert } from './components/section/Expert/Expert'
import { Footer } from './components/Footer/Footer'
import { Popup } from './components/Popup/Popup'
import { Background } from './icons/Background/Background'
import { DashboardLink } from './components/DashboardLink/DashboardLink'
import { ChangeMobilePageBtn } from './components/ChangeMobilePageBtn/ChangeMobilePageBtn'
import {SuccessText} from "./components/SuccessText/SuccessText";
import {linkSection} from "./config/linkSection";

class App extends Component {
	state = {
		version: 'desktop-ver',
		formType: 'default',
		showPopup: false,
		dashboardLink: [],
		showMobileContent: false,
		sendForm: false,
	}

	ym = null;
	ga = null;

	componentDidMount() {
		this.ym = typeof window.ym === 'undefined' ? () => {} : window.ym;
		this.ga = typeof window.ga === 'undefined' ? () => {} : window.ga;

		this.breakpoint = new MatchMediaBreakpoint({
			breakpoints: BREAKPOINTS,
			onBreakpointChange: (currentBreakpoint, previousBreakpoint) => {
				if (currentBreakpoint !== previousBreakpoint) {
					this.setState({ breakpoint: currentBreakpoint })
				}
			}
		})

		const version =
			this.breakpoint.value === 'desktop' ||
			this.breakpoint.value === 'largeDesktop'
				? 'desktop-ver'
				: 'mobile-ver'

		this.setState({ version })

		const dashboardArr = []
		const section = document.querySelectorAll('section')
		section.forEach((item) => {
			if (item.getAttribute('data-name') !== null) {
				dashboardArr.push({
					link: item.id,
					title: item.getAttribute('data-name')
				})
			}
		})

		this.setState({
			dashboardLink: dashboardArr
		}, () => {
			const initLink = new linkSection()
			initLink.init()
		})
	}

	currentTypeForm = (typeForm = 'default', main) => {
		if (!main) {
			this.ym(69798862,'reachGoal','VoteClick')

			return
		}

		this.ym(69798862,'reachGoal','VoteClick')

		this.setState({
			formType: typeForm,
			showPopup: true
		})
	}

	successText = () => {
		this.ym(69798862,'reachGoal','VoteSubmit')

		this.setState((prevState) => ({
			sendForm: !prevState.sendForm
		}))
	}

	btnHiddenPopup = () => {
		this.setState({
			showPopup: false
		})
	}

	changeMobileType = (type) => {
		this.setState({
			formType: type,
			showMobileContent: true
		})
	}

	changePage = (type) => {
		this.setState({
			formType: type === 'red' ? 'blue' : 'red'
		})
	}

	render() {
		const { showMobileContent, formType, version, sendForm } = this.state

		return (
			<div className={`app ${version} ${formType}`}>
				<div className="main-content">
					<RunningString />
					{version === 'desktop-ver' && (
						<>
							<div className="container">
								<Offer
									version={version}
									formType={formType}
									showMobileContent={showMobileContent}
								/>
								<CurrentProject version={version} formType={formType}/>
								<Advantages />
								<Chosen />
								<FormSection
									currentTypeForm={this.currentTypeForm}
									main={true}
									version={version}
									formType={formType}
								/>
								<Expert version={version} />
								<Footer />
							</div>
							<div className={'background-blur'}>
								<Background />
							</div>
						</>
					)}
					{version === 'mobile-ver' && (
						<>
							<div className="container">
								<Offer
									version={version}
									formType={formType}
									showMobileContent={showMobileContent}
									changeMobileType={this.changeMobileType}
								/>
							</div>
							{showMobileContent && (
								<>
									<div className="container">
										<CurrentProject version={version} formType={formType} />
										<Advantages version={version} formType={formType} />
										<Chosen version={version} formType={formType} />
										{!sendForm && (
											<FormSection
												currentTypeForm={this.currentTypeForm}
												main={true}
												version={version}
												formType={formType}
												successText={this.successText}
											/>
										)}
										{sendForm && (
											<SuccessText successText={this.successText}/>
										)}
									</div>
									<Expert version={version} />
									<Footer version={version} />
								</>
							)}
							{showMobileContent && (
								<>
									<div className={'background-blur'}>
										<Background />
									</div>
									<ChangeMobilePageBtn
										title={
											formType === 'red'
												? 'Аутстаффинг разработчиков'
												: 'Проектные команды'
										}
										formType={formType}
										changePage={this.changePage}
									/>
								</>
							)}
						</>
					)}
					{this.state.showPopup && version === 'desktop-ver' && (
						<Popup
							type={formType}
							currentTypeForm={this.currentTypeForm}
							btnHiddenPopup={this.btnHiddenPopup}
							formType={formType}
							sendForm={sendForm}
							successText={this.successText}/>
					)}
				</div>
				{version !== 'mobile-ver' && (
					<DashboardLink dashboardLink={this.state.dashboardLink} />
				)}
			</div>
		)
	}
}

export { App }
