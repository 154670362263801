import React, { Component } from 'react'
import styles from './Popup.module.scss'
import { RunningString } from '../RunningString/RunningString'
import { Form } from '../Form/Form'
import { SuccessText } from '../SuccessText/SuccessText'

class Popup extends Component {
  state = {
    className: null
  }

  componentDidMount() {
    setTimeout(() =>
      this.setState({
        className: `${styles[`show`]}`
      }), 0)
  }

  render() {
		const {
			type,
			currentTypeForm,
			btnHiddenPopup,
			formType,
			sendForm,
			successText
		} = this.props

		return (
			<div className={`${styles['popup__overlay']} ${this.state.className}`}>
				<div className={`${styles['popup__form']} ${styles[type]}`}>
					<RunningString />
					<div className="container">
						<div className={styles['popup__form-block']}>
							<div className={styles['popup__form-wrapper']}>
								{!sendForm && (
									<Form
										title={
											type === 'red'
												? 'Выбираю проектную команду'
												: 'Выбираю аутстаф'
										}
										type={type}
										currentTypeForm={currentTypeForm}
										formType={formType}
										successText={successText}
									/>
								)}
								{sendForm && <SuccessText successText={successText} />}
							</div>
						</div>
					</div>
					<div
						className={`${styles['popup__wrapper']} ${styles[type]}`}>
							<div className={styles['popup__wrapper-img']}>
								<img src="img/handle.png" alt="" />
							</div>
						<div className={styles['popup__btn']} onClick={btnHiddenPopup}>
							Вернуться
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export { Popup }
