const BREAKPOINTS = [
	{ breakpoint: 'mobile', width: 0 },
	{ breakpoint: 'tablet', width: 768 },
	{ breakpoint: 'desktop', width: 1200 },
	{ breakpoint: 'largeDesktop', width: 1920 }
]

const runningString = [
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 1
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 2
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 3
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 4
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 5
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 6
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 7
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 8
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 9
	},
	{
		string: 'Make Development',
		importantStr: ' Great Again',
		quantity: 10
	}
]

const offerText = [
	{
		title: 'Проектные команды',
		description:
			'Сработанные команды, включающие разработчиков, team- / tech-лидов, системных архитекторов, Q&A-инженеров, аналитиков, UI/UX-дизайнеров',
		type: 'red'
	},
	{
		title: 'Аутстафф разработчиков',
		description:
			'Специалисты с необходимыми вам навыками, знаниями и экспертизой (Middle/Senior) под вашим менеджментом',
		type: 'blue'
	}
]

const cardProjectInfo = [
	{
		type: 'red',
		list: [
			{
				title: 'Горят сроки',
				description: 'проекта, а усилиться некем',
				src: '/img/cardProject/fire.png'
			},
			{
				title: 'Жесткий дедлайн',
				description: 'а внутри текучка кадров и смена ответственных',
				src: '/img/cardProject/watch.png'
			},
			{
				title: 'Нет результата',
				description: 'хоть и есть штатная команда',
				src: '/img/cardProject/arrow.png'
			},
			{
				title: 'Нет продуктовой экспертизы',
				description:
					'или этап проектирования планируется реализовать вместе с подрядчиком',
				src: '/img/cardProject/cube.png'
			}
		]
	},
	{
		type: 'blue',
		list: [
			{
				title: 'Не хватает рук',
				description: 'нужно усилить in-house команду',
				src: '/img/cardProject/zap.png'
			},
			{
				title: 'Нужны люди on-site',
				description: 'в локации заказчика для работы внутри команды',
				src: '/img/cardProject/pin.png'
			},
			{
				title: 'Постоянная смена требований',
				description: 'на проекте в процессе разработки',
				src: '/img/cardProject/layers.png'
			},
			{
				title: 'Нужны выделенные роли',
				description: 'для усиления команды (например, только аналитик)',
				src: '/img/cardProject/wheel.png'
			}
		]
	}
]

const advantagesList = [
  {
    red: {
      title: '100% гарантия результата'
    },
    blue: {
      title: 'Отсутствие простоев, связанных с больничными и  отпуском'
    }
  },
  {
    red: {
      title: 'Сокращения срока выхода релиза на 10-20% в зависимости от задач и размера команды'
    },
    blue: {
      title: 'Сокращение расходов на штатных специалистов, оптимизация «зарплатных» налогов'
    }
  },
  {
    red: {
      title: 'Снижение затрат на штат разработчиков на 25%'
    },
    blue: {
      title: 'Быстрый поиск и замена IT-специалиста'
    }
  },
]

const chosen = {
	red: [
		'/img/chosen/LOGO.png',
		'/img/chosen/gpb.png',
		'/img/chosen/otkr.png',
		'/img/chosen/logo_rt.png',
	],
	blue: [
		'/img/chosen/sber.png',
		'/img/chosen/alpha.png',
		'/img/chosen/ntv.png',
		'/img/chosen/perekrestok.png',
	]
}

const expertList = [
	{
		src: '/img/expert/smartphone.svg',
		title: 'Mobile',
		description: 'Swift, Obj-C, Kotlin, Java, React Native, Flutter, etc.'
	},
	{
		src: '/img/expert/server.svg',
		title: 'Back End',
		description: 'Java, Python, PHP, Go, etc'
	},
	{
		src: '/img/expert/layout.svg',
		title: 'Front End',
		description: 'Angular, ReactJS, Vue.js, etc.'
	},
	{
		src: '/img/expert/pen-tool.svg',
		title: 'Дизайн',
		description: 'UX/UI'
	}
]

export {
	BREAKPOINTS,
	runningString,
	offerText,
	cardProjectInfo,
	advantagesList,
	chosen,
	expertList
}
