import React from 'react'

const Background = () => (
  <svg width="1920" height="864" viewBox="0 0 1920 864" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g opacity="0.3" filter="url(#filter0_f)">
        <path d="M460.175 1218.54L892.119 908.522C923.003 886.355 942.646 851.82 945.894 813.979L984.521 363.977C989.48 306.195 955.546 252.111 901.313 231.363L389.306 35.4824C347.42 19.4578 300.95 20.2317 259.622 37.6422L-144.345 207.824C-196.521 229.805 -228.506 282.921 -223.483 339.246L-180.803 817.898C-177.408 855.979 -157.412 890.642 -126.127 912.68L309.019 1219.21C354.399 1251.17 415.081 1250.91 460.175 1218.54Z" fill="#DA534C"/>
      </g>
      <g opacity="0.3" filter="url(#filter1_f)">
        <path d="M249.058 1045.87L393.04 942.873C403.334 935.509 409.882 924.035 410.965 911.462L423.84 761.954C425.493 742.756 414.182 724.787 396.104 717.894L225.435 652.815C211.473 647.491 195.983 647.748 182.207 653.532L47.5516 710.074C30.1597 717.376 19.4981 735.024 21.1722 753.737L35.3989 912.764C36.5308 925.416 43.1961 936.933 53.6245 944.255L198.673 1046.1C213.8 1056.72 234.027 1056.63 249.058 1045.87Z" fill="#DA534C"/>
      </g>
      <g opacity="0.3" filter="url(#filter2_f)">
        <path d="M704.491 747.482L802.027 677.543C809.001 672.543 813.436 664.752 814.17 656.215L822.892 554.697C824.012 541.662 816.349 529.461 804.103 524.78L688.488 480.59C679.03 476.975 668.537 477.15 659.205 481.078L567.987 519.47C556.205 524.428 548.983 536.411 550.117 549.118L559.754 657.099C560.521 665.69 565.036 673.51 572.1 678.482L670.359 747.633C680.606 754.844 694.309 754.784 704.491 747.482Z" fill="#DA534C"/>
      </g>
      <g opacity="0.3" filter="url(#filter3_f)">
        <path d="M1073.3 1081.15L1229.43 968.995C1240.59 960.976 1247.69 948.482 1248.87 934.792L1262.83 771.994C1264.62 751.09 1252.35 731.524 1232.75 724.018L1047.68 653.154C1032.54 647.357 1015.75 647.637 1000.81 653.935L854.792 715.502C835.932 723.454 824.371 742.67 826.187 763.047L841.614 936.21C842.841 949.987 850.069 962.527 861.377 970.499L1018.66 1081.39C1035.07 1092.96 1057 1092.86 1073.3 1081.15Z" fill="#DA534C"/>
      </g>
      <g opacity="0.2" filter="url(#filter4_f)">
        <path d="M1279.9 1166.99L1545 976.758C1563.95 963.156 1576.01 941.965 1578 918.745L1601.71 642.616C1604.75 607.16 1583.92 573.973 1550.64 561.241L1236.4 441.046C1210.7 431.213 1182.18 431.688 1156.81 442.371L908.887 546.798C876.865 560.285 857.235 592.878 860.317 627.441L886.511 921.15C888.595 944.517 900.867 965.787 920.068 979.31L1187.13 1167.4C1214.98 1187.02 1252.22 1186.85 1279.9 1166.99Z" fill="#276CF7"/>
      </g>
      <g opacity="0.4" filter="url(#filter5_f)">
        <path d="M1956.07 842.349L2155.61 561.728C2169.87 541.664 2174.66 516.373 2168.71 492.492L2097.95 208.509C2088.86 172.044 2056.73 146 2019.15 144.65L1664.42 131.9C1635.4 130.857 1607.1 141.074 1585.44 160.412L1373.7 349.437C1346.35 373.851 1337.9 413.121 1352.8 446.6L1479.38 731.102C1489.45 753.737 1508.97 770.796 1532.76 777.748L1863.62 874.447C1898.13 884.531 1935.24 871.645 1956.07 842.349Z" fill="#276CF7"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_f" x="-448" y="-200" width="1657" height="1667" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="112" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter1_f" x="-203" y="425" width="851" height="853" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="112" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter2_f" x="326" y="254" width="721" height="723" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="112" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter3_f" x="602" y="425" width="885" height="889" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="112" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter4_f" x="636" y="210" width="1190" height="1196" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="112" result="effect1_foregroundBlur"/>
      </filter>
      <filter id="filter5_f" x="1121.52" y="-92.1719" width="1273.69" height="1194.01" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="112" result="effect1_foregroundBlur"/>
      </filter>
      <clipPath id="clip0">
        <rect width="1920" height="864" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export {Background}