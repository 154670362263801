import React from 'react'
import { CardProject } from '../../CardProject/CardProject'
import { cardProjectInfo } from '../../../config/constants'
import styles from './CurrentProject.module.scss'

const CurrentProject = (props) => (
	<section
		className={`${styles['current-project']} content-wrapper`}
		id="current-project"
		data-name="Проекты"
	>
		<div className={`${styles['current-project__caption']} caption-title`}>
			На текущем проекте:
		</div>
		<div className={`${styles['current-project__wrapper']} ${styles[props.version]} ${styles[props.formType]}`}>
			{cardProjectInfo.map((item, idx) => (
				<div className={`${styles['current-project__block']} ${styles[item.type]}`} key={idx}>
					{item.list.map((value, index) => (
						<CardProject info={value} key={index} type={item.type}/>
					))}
				</div>
			))}
		</div>
	</section>
)

export { CurrentProject }
