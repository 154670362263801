import { runningString } from '../../config/constants'
import styles from './RunningString.module.scss'
import Marquee from 'react-double-marquee/dist/bundle.esm'
import React from 'react'

const RunningString = () => {
	return (
		<div className={styles['running-string']}>
			<div className={styles['running-string__wrapper']}>
				<Marquee delay='0'>
					{runningString.map((item) => (
						<React.Fragment key={item.quantity}>
							{item.string}
							<span>{item.importantStr}</span>
						</React.Fragment>
					))}
				</Marquee>
			</div>
		</div>
	)
}

export { RunningString }
