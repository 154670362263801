import React from 'react'
import { Form } from '../../Form/Form'
import styles from './FormSection.module.scss'

const FormSection = (props) => (
	<section
		className={`${styles['form-section']} ${styles[props.formType]} ${
			styles[props.version]
		}`}
		id="form-section"
	>
		{props.version === 'mobile-ver' && props.formType === 'red' && (
			<Form
				title={'Выбираю проектную команду'}
				currentTypeForm={props.currentTypeForm}
				main={props.main}
				type={'red'}
				formType={props.formType}
				successText={props.successText}
			/>
		)}
		{props.version === 'mobile-ver' && props.formType === 'blue' && (
			<Form
				title={'Выбираю аутстафф разработчиков'}
				currentTypeForm={props.currentTypeForm}
				main={props.main}
				type={'blue'}
				formType={props.formType}
				successText={props.successText}
			/>
		)}
		{props.version === 'desktop-ver' && (
			<>
				<Form
					title={'Выбираю проектную команду'}
					currentTypeForm={props.currentTypeForm}
					main={props.main}
					type={'red'}
					formType={props.formType}
				/>

				<Form
					title={'Выбираю аутстафф разработчиков'}
					currentTypeForm={props.currentTypeForm}
					main={props.main}
					type={'blue'}
					formType={props.formType}
				/>
			</>
		)}
	</section>
)

export { FormSection }
